<!--统计报表-油烟减排统计-->
<template>
  <section>
    <!-- 工具条 -->
    <el-col :span='24' class='toolbar' style='padding-bottom: 0px;'>
      <el-form :inline='true' :model='filter' size="mini">
        <el-form-item>
          <el-cascader
            v-model="filter.owner"
            placeholder="所属单位"
            :options="customerTree"
            :props="orgProps"
            clearable
            filterable/>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="filter.peorid"
            type="daterange"
            value-format="timestamp"
            @change="getData"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type='primary' @click='getData'>查询</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <!-- 列表 -->
    <el-col :span='24'>
      <el-table :data='datalist.content' show-summary size='mini' border highlight-current-row v-loading='loading'
                :max-height='clientHeight' style='width: 100%'>
        <el-table-column type='index' label='#' align='center' width='55'/>
        <el-table-column prop='LocaleName' label='监测点' show-overflow-tooltip width='260'
                         header-align='center'/>
        <el-table-column prop='LocaleAddr' label='安装地址' show-overflow-tooltip width='260'
                         header-align='center'/>
        <el-table-column prop='MN' label='设备编码' show-overflow-tooltip width='180' align='center'
                         header-align='center'/>
        <!-- <el-table-column prop='Owner' label='所属单位' show-overflow-tooltip width='260' :formatter="localeFormatter" header-align='center' />
        <el-table-column prop='AcquitAt' label='采集时间' :formatter="dateFormat" align='center' header-align='center' />
        <el-table-column prop='Emissions' label='油烟排放量(Kg)' align='center' header-align='center' />
        <el-table-column prop='Granule' label='颗粒物含量(Kg)' align='center' header-align='center' />
        <el-table-column prop='Hydrocarbon' label='非甲烷总烃(Kg)' align='center' header-align='center' /> -->
        <el-table-column prop='RedPm25' label='PM2.5减排量(Kg)' align='center' header-align='center'/>
        <el-table-column prop='RedPm10' label='PM10减排量(Kg)' align='center' header-align='center'/>
        <el-table-column prop='RedEmissions' label='油烟减排量(Kg)' align='center' header-align='center'/>
        <el-table-column prop='RedVocs' label='VOCS减排量(Kg)' align='center' header-align='center'/>
      </el-table>
    </el-col>

    <!-- 工具条 -->
    <el-col :span='24' class='toolbar'>
      <el-pagination small background @size-change="(v)=>handlePageChange(v, 'size')"
                     @current-change="(v)=>handlePageChange(v, 'page')" :current-page.sync='filter.page'
                     :page-sizes='filter.pageSizes' layout='total, sizes, prev, pager, next, jumper'
                     :total='datalist.total' style='display:inline-block;margin-left:15px'>
      </el-pagination>
    </el-col>
  </section>
</template>

<script>
import {mapState} from 'vuex'
import {join} from '@/util'

export default {
  data() {
    return {
      changeState: false,
      loading: false,
      datalist: {},
      filter: {
        owner: null,
        peorid: null,
        page: 1,
        size: 20,
        pageSizes: [20, 30, 50]
      }
    }
  },
  computed: {
    ...mapState(['props', 'customerTree', 'clientHeight']),
    ...mapState({
      orgProps: state => Object.assign({}, state.props, {label: 'Org', checkStrictly: true})
    })
  },
  mounted() {
    this.getData()
  },
  methods: {
    hasChange() {
      this.changeState = true
    },
    getData() {
      this.loading = true
      var para = {StartAt: (this.filter.page - 1) * this.filter.size, Size: this.filter.size}
      para.Param = {}
      if (this.filter.owner) {
        para.Param['Owner'] = join(this.filter.owner)
      }
      if (!this.filter.peorid) {
        this.filter.peorid = []
        const now = new Date()
        now.setHours(0, 0, 0, 0)
        this.filter.peorid[1] = now.getTime() - 1000
        now.setDate(now.getDate() - 30)
        this.filter.peorid[0] = now.getTime()
      }
      para.Param['startAt'] = this.filter.peorid[0] / 1000
      para.Param['endAt'] = this.filter.peorid[1] / 1000
      this.$post('admin/listDetectorDaily', para).then(res => {
        this.datalist = res
        this.loading = false
      })
    },
    handleRefresh() {
      this.filter.page = 1
      this.filter.name = null
      this.getData()
    },
    selsChange(sels) {
      this.sels = sels
    },
    handlePageChange(val, field) {
      this.filter[field] = val
      this.getData()
    }
  }
}
</script>
